import React from 'react'
import { motion } from 'framer-motion'
import { Facebook, Twitter, Instagram, Linkedin, Mail, Phone } from 'lucide-react'
import Logo from './logo_blanco.svg'
export default function Footer() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  }

  const socialLinks = [
]

  const footerLinks = [
    { text: 'Inicio', url: '#' },
    { text: 'Características', url: '#caracteristicas' },
    { text: 'Casos de Uso', url: '#casos-de-uso' },
    { text: 'Precios', url: '#precios' },
    { text: 'Contacto', url: '#contacto' }
  ]

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <motion.div 
            className="footer-logo"
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
          >
            <img 
            

              src={Logo} 
              alt="Escana Logo" 
              Style="height:75px;"
              className="logo"
            />
          </motion.div>
          <motion.nav 
            className="direccion"
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
            Style="margin-top:auto;margin-bottom:auto;"
          >
          San Martín 533 Oficina 901, Concepción, Región del Bío-Bío. Chile.
          </motion.nav>
          <motion.div 
            className="footer-contact"
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
          >
            <a href="mailto:Info@escana.cl" className="footer-contact-item">
              <Mail size={20} />
              <span>Info@escana.cl</span>
            </a>
            <a href="mailto:Info@escana.cl" className="footer-contact-item">
              <Phone size={20} />
              <span>Pablo@escana.cl</span>
            </a>
          </motion.div>
          <motion.div 
            className="footer-social"
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
          >
            {socialLinks.map((link, index) => (
              <a key={index} href={link.url} className="footer-social-link">
                {link.icon}
              </a>
            ))}
          </motion.div>
        </div>
        <motion.div 
          className="footer-bottom"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          <p>&copy; {new Date().getFullYear()} Escana. Todos los derechos reservados.</p>
        </motion.div>
      </div>
    </footer>
  )
}