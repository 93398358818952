import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Menu, X } from 'lucide-react'

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => setMenuOpen(!menuOpen)

  return (
    <header className="header">
      <div className="container">
        <div className="header-content">
          <div className="logo-container">
            <motion.img
              whileHover={{ scale: 1.05 }}
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Logo%20principal%20(1)-CsVSuWQ2PnSCq8owjvJ5fHvYdAy5XL.png"
              alt="Escana Logo"
              className="logo"
            />
          </div>
          <nav className="nav">
            {['Características', 'Demo', '¿Por qué Escana?', 'Casos de Uso', 'Contacto'].map((item) => (
              <motion.a
                key={item}
                href={`#${item.toLowerCase().replace(' ', '-')}`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {item}
              </motion.a>
            ))}
          </nav>
          <div className="cta-button">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <button className="button">
                Chatea con nosotros
              </button>
            </motion.div>
          </div>
          <motion.button
            className="menu-button"
            onClick={toggleMenu}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {menuOpen ? <X /> : <Menu />}
          </motion.button>
        </div>
      </div>
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="mobile-menu"
          >
            <nav className="mobile-nav">
              {['Características', 'Demo', 'Beneficios', 'Casos de Uso', 'Contacto'].map((item) => (
                <motion.a
                  key={item}
                  href={`#${item.toLowerCase().replace(' ', '-')}`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={toggleMenu}
                >
                  {item}
                </motion.a>
              ))}
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <button className="button mobile-cta">
                  Chatea con nosotros
                </button>
              </motion.div>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  )
}