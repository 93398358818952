import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Play } from 'lucide-react'
import Principal from './escana_principal.png'
import Video from './video_escana.mp4'

export default function VideoDemo() {
  const [isPlaying, setIsPlaying] = useState(false)

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  }

  const handlePlayClick = () => {
    setIsPlaying(true)
  }

  return (
    <section id="demo" className="video-demo">
      <div className="container">
        <motion.h2 
          className="section-title"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          Descubre Escana en Acción
        </motion.h2>
        <motion.p 
          className="section-subtitle"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
          custom={1}
        >
          Mira cómo Escana revoluciona la gestión de accesos en tiempo real
        </motion.p>
        <motion.div 
          className="video-container"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
          custom={2}
        >
          {!isPlaying ? (
            <div className="video-placeholder">
              <img 
                src={Principal} 
                alt="Video thumbnail" 
                className="video-thumbnail"
              />
              <button className="play-button" onClick={handlePlayClick}>
                <Play size={48} />
              </button>
            </div>
          ) : (
            <iframe
              width="854"
              height="480"
              src={Video}
              title="Escana Demo Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </motion.div>
      </div>
    </section>
  )
}