import React from 'react'
import Header from './Header'
import Hero from './Hero'
import Features from './Features'
import VideoDemo from './VideoDemo'
import Benefits from './Benefits'
import UseCases from './UseCases'
import Testimonials from './Testimonials'
import Progress from './Progress'
import FAQ from './FAQ'
import Footer from './Footer'
import FinalCTA from './FinalCTA'
import './estilos.css'

export default function EscanaLandingPage() {
  return (
    <div className="landing-page">
      <Header />
      <Hero />
      <Features />
      <VideoDemo />
      <Benefits />
      <UseCases />
      <Testimonials />
      <Progress />
      <FAQ />
      <Footer />
      <FinalCTA />
    </div>
  )
}