import React from 'react'
import { motion } from 'framer-motion'
import { Music, Beer, Ticket, Coffee, Users, Building } from 'lucide-react'

export default function UseCases() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  }

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
  }

  const sectors = [
    { icon: <Music />, title: 'Discotecas' },
    { icon: <Beer />, title: 'Bares' },
    { icon: <Ticket />, title: 'Festivales' },
    { icon: <Coffee />, title: 'Clubes Nocturnos' },
    { icon: <Users />, title: 'Eventos Corporativos' },
    { icon: <Building />, title: 'Casinos' }
  ]

  return (
    <section id="casos-de%20uso" className="use-cases">
      <div className="container">
        <motion.h2 
          variants={fadeInUp} 
          initial="hidden"
          animate="visible"
          className="section-title"
        >
          Sectores que Confían en Escana
        </motion.h2>
        <motion.p 
          variants={fadeInUp} 
          initial="hidden"
          animate="visible"
          className="section-subtitle"
        >
          Nuestra solución se adapta a diversos entornos de entretenimiento y eventos
        </motion.p>
        <motion.div 
          variants={staggerChildren}
          initial="hidden"
          animate="visible" 
          className="use-cases-grid"
        >
          {sectors.map((sector, index) => (
            <motion.div key={index} variants={fadeInUp} className="use-case-card">
              <div className="use-case-icon-wrapper">
                {sector.icon}
              </div>
              <h3 className="use-case-title">{sector.title}</h3>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  )
}