import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { ArrowRight } from 'lucide-react'

export default function FinalCTA() {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    // Aquí iría la lógica para enviar los datos del formulario
    console.log('Formulario enviado:', { name, email, company })
    setSubmitted(true)
  }

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  }

  return (
    <section id="contacto" className="final-cta">
      <div className="container">
        <motion.div 
          className="cta-content"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          <h2 className="cta-title">Únete a la Lista de Espera</h2>
          <p className="cta-description">
            Sé de los primeros en experimentar la revolución en la gestión de accesos con Escana.
          </p>
        </motion.div>
        {!submitted ? (
          <motion.form 
            className="cta-form"
            onSubmit={handleSubmit}
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
          >
            <div className="form-group">
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
                required
                className="form-input"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Correo electrónico"
                required
                className="form-input"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                id="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                placeholder="Empresa"
                className="form-input"
              />
            </div>
            <button type="submit" className="submit-button">
              Unirme a la lista <ArrowRight size={20} />
            </button>
          </motion.form>
        ) : (
          <motion.div 
            className="success-message"
            variants={fadeInUp}
            initial="hidden"
            animate="visible"
          >
            ¡Gracias por unirte! Te contactaremos pronto con más información.
          </motion.div>
        )}
      </div>
    </section>
  )
}