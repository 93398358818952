import React, { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'

export default function Testimonials() {
  const carouselRef = useRef(null)

  const testimonials = [
    {
      name: "Carlos Rodríguez",
      position: "Gerente de Discoteca",
      content: "Escana ha revolucionado nuestro control de acceso. La verificación rápida y la gestión de listas VIP han mejorado significativamente la experiencia de nuestros clientes."
    },
    {
      name: "Laura Martínez",
      position: "Organizadora de Eventos",
      content: "Con Escana, la gestión de grandes eventos se ha vuelto mucho más eficiente. El sistema de escaneo rápido nos permite manejar grandes volúmenes de asistentes sin problemas."
    },
    {
      name: "Javier Sánchez",
      position: "Propietario de Bar",
      content: "La función de baneo y la red compartida de Escana han mejorado notablemente la seguridad en nuestro local. Ahora podemos prevenir problemas antes de que ocurran."
    },
    {
      name: "Ana Gómez",
      position: "Seguridad de Casino",
      content: "La verificación de identidad de Escana es rápida y precisa. Ha simplificado nuestro proceso de entrada y mejorado la seguridad general del casino."
    }
  ]

  useEffect(() => {
    const carousel = carouselRef.current
    let currentIndex = 0

    const showTestimonial = (index) => {
      const items = carousel.querySelectorAll('.testimonial-item')
      items.forEach((item, i) => {
        item.style.transform = `translateX(${100 * (i - index)}%)`
      })
    }

    const nextTestimonial = () => {
      currentIndex = (currentIndex + 1) % testimonials.length
      showTestimonial(currentIndex)
    }

    const interval = setInterval(nextTestimonial, 5000)
    showTestimonial(currentIndex)

    return () => clearInterval(interval)
  }, [testimonials.length])

  return (
    <section id="testimonios" className="testimonials">
      <div className="container">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="section-title"
        >
          Lo que dicen nuestros usuarios
        </motion.h2>
        <motion.p 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="section-subtitle"
        >
          Descubre cómo Escana está transformando la industria del entretenimiento y eventos
        </motion.p>
        <div className="testimonial-carousel" ref={carouselRef}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <p className="testimonial-content">{testimonial.content}</p>
              <div className="testimonial-author">
                <strong>{testimonial.name}</strong>
                <span>{testimonial.position}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}