import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDown } from 'lucide-react'

export default function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null)

  const faqs = [
    {
      question: "¿Cómo se integra Escana con los sistemas existentes?",
      answer: "Escana está diseñado para integrarse fácilmente con la mayoría de los sistemas de gestión de eventos y control de acceso. Ofrecemos APIs y documentación detallada para una integración sin problemas."
    },
    {
      question: "¿Qué tipo de hardware se necesita para usar Escana?",
      answer: "Escana es compatible con la mayoría de los dispositivos móviles y tabletas modernas. Para un rendimiento óptimo, recomendamos dispositivos con cámara de alta resolución y conexión a internet estable."
    },
    {
      question: "¿Cómo maneja Escana la privacidad de los datos?",
      answer: `
      
      Chile: Ley Nº 19.628 sobre Protección de la Vida Privada
Resumen: Esta ley regula el tratamiento de datos personales y busca proteger la privacidad de los individuos. Establece que los datos sólo pueden ser utilizados con el consentimiento del titular y para los fines que fueron recogidos.
Aspectos clave para escana:
Consentimiento del titular: Necesario para el tratamiento de datos personales.
Derechos del titular: Las personas tienen derecho a acceder, rectificar, cancelar o bloquear sus datos si no son tratados conforme a la ley.
Finalidad: Los datos personales sólo pueden ser tratados para los fines específicos autorizados por el titular.`
      
    },
    {
      question: "¿Ofrece Escana soporte técnico?",
      answer: "Sí, ofrecemos soporte técnico 24/7 para todos nuestros clientes. Esto incluye asistencia por correo electrónico, chat en vivo y soporte telefónico para problemas críticos."
    },
    {
      question: "¿Puede Escana funcionar sin conexión a internet?",
      answer: "Escana está diseñado principalmente para funcionar con conexión a internet para garantizar datos en tiempo real. Sin embargo, ofrecemos un modo fuera de línea limitado para situaciones de conectividad intermitente."
    }
  ]

  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  return (
    <section id="faq" className="faq">
      <div className="container">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="section-title"
        >
          Preguntas Frecuentes
        </motion.h2>
        <motion.p 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="section-subtitle"
        >
          Encuentra respuestas a las preguntas más comunes sobre Escana
        </motion.p>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="faq-item"
            >
              <button 
                className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                onClick={() => toggleQuestion(index)}
              >
                {faq.question}
                <ChevronDown className={`faq-icon ${activeIndex === index ? 'rotate' : ''}`} />
              </button>
              <AnimatePresence>
                {activeIndex === index && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="faq-answer"
                  >
                    {faq.answer}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}