import React from 'react'
import { motion } from 'framer-motion'
import { Tablet, Zap, ClipboardList, UserX, Network, Smartphone } from 'lucide-react'

export default function Features() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  }

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
  }

  const features = [
    {
      icon: <Smartphone />,
      title: 'Integración Móvil Sencilla',
      description: 'Fácil de integrar en cualquier dispositivo móvil, permitiendo una implementación rápida y sin complicaciones.'
    },
    {
      icon: <Tablet />,
      title: 'Optimizado para Tabletas',
      description: 'Diseñado idealmente para tabletas, ofreciendo una interfaz intuitiva y fácil de usar en pantallas más grandes.'
    },
    {
      icon: <Zap />,
      title: 'Escaneo Rápido',
      description: 'Escaneo efectivo en solo 2 clics, agilizando el proceso de entrada y reduciendo las colas.'
    },
    {
      icon: <ClipboardList />,
      title: 'Gestión Inteligente de Invitados',
      description: 'Sistema avanzado para manejar listas de invitados, facilitando el control de acceso VIP y eventos especiales.'
    },
    {
      icon: <UserX />,
      title: 'Sistema de Baneo',
      description: 'Capacidad para banear individuos con mal comportamiento, mejorando la seguridad y el ambiente del local.'
    },
    {
      icon: <Network />,
      title: 'Red de Baneados',
      description: 'Conexión a una red compartida de individuos baneados, permitiendo una colaboración entre locales para mayor seguridad.'
    }
  ]

  return (
    <section id="características" className="features">
      <div className="container">
        <motion.h2 
          variants={fadeInUp} 
          initial="hidden"
          animate="visible"
          className="section-title"
        >
          Características Principales de Escana
        </motion.h2>
        <motion.p 
          variants={fadeInUp} 
          initial="hidden"
          animate="visible"
          className="section-subtitle"
        >
          Descubre cómo Escana revoluciona la gestión de acceso y seguridad en locales nocturnos
        </motion.p>
        <motion.div 
          variants={staggerChildren}
          initial="hidden"
          animate="visible" 
          className="features-grid"
        >
          {features.map((feature, index) => (
            <motion.div key={index} variants={fadeInUp} className="feature-card">
              <div className="feature-icon-wrapper">
                {feature.icon}
              </div>
              <h3 className="feature-title">{feature.title}</h3>
              <p className="feature-description">{feature.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  )
}