import React from 'react'
import { motion } from 'framer-motion'
import { Check, Clock } from 'lucide-react'

export default function Roadmap() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  }

  const stages = [
    { title: 'Investigación y Planificación', status: 'completed', description: 'Análisis de mercado y definición de características clave.' },
    { title: 'Desarrollo del Prototipo', status: 'completed', description: 'Creación de la primera versión funcional de Escana.' },
    { title: 'Pruebas Beta', status: 'inProgress', description: 'Implementación en locales seleccionados para pruebas y retroalimentación.' },
    { title: 'Optimización y Mejoras', status: 'upcoming', description: 'Refinamiento basado en la retroalimentación de las pruebas beta.' },
    { title: 'Lanzamiento Oficial', status: 'upcoming', description: 'Despliegue completo de Escana en el mercado.' }
  ]

  return (
    <section id="roadmap" className="roadmap">
      <div className="container">
        <motion.h2 
          variants={fadeInUp} 
          initial="hidden"
          animate="visible"
          className="section-title"
        >
          Roadmap de Desarrollo
        </motion.h2>
        <motion.p 
          variants={fadeInUp} 
          initial="hidden"
          animate="visible"
          className="section-subtitle"
        >
          Seguimiento del progreso de Escana hacia su lanzamiento oficial
        </motion.p>
        <div className="roadmap-container">
          {stages.map((stage, index) => (
            <motion.div 
              key={index}
              className={`roadmap-item ${stage.status}`}
              variants={fadeInUp}
              initial="hidden"
              animate="visible"
              custom={index}
            >
              <div className="roadmap-point">
                {stage.status === 'completed' ? (
                  <Check className="icon completed" />
                ) : stage.status === 'inProgress' ? (
                  <Clock className="icon in-progress" />
                ) : (
                  <div className="icon upcoming" />
                )}
              </div>
              <div className="roadmap-content">
                <h3>{stage.title}</h3>
                <p>{stage.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}