import React from 'react'
import { motion } from 'framer-motion'
import { Shield, Users, List, BarChart, Clock, Lock } from 'lucide-react'

export default function Benefits() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  }

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
  }

  const benefits = [
    { 
      icon: <Shield />, 
      title: 'Aumenta la Seguridad', 
      description: 'Verifica identidades en segundos, previniendo el ingreso de personas no deseadas y mejorando la seguridad general del local.'
    },
    { 
      icon: <Users />, 
      title: 'Reduce Atascos', 
      description: 'Agiliza el proceso de entrada, disminuyendo las filas y mejorando la experiencia de tus clientes desde el primer momento.'
    },
    { 
      icon: <List />, 
      title: 'Gestión de Listas VIP', 
      description: 'Maneja eficientemente listas de invitados y VIPs, asegurando un trato preferencial y organizado para tus clientes especiales.'
    },
    { 
      icon: <BarChart />, 
      title: 'Análisis en Tiempo Real', 
      description: 'Obtén datos valiosos sobre el flujo de clientes, permitiéndote tomar decisiones informadas para optimizar tu negocio.'
    },
    { 
      icon: <Clock />, 
      title: 'Ahorro de Tiempo', 
      description: 'Automatiza procesos manuales, liberando a tu personal para que se enfoque en proporcionar un mejor servicio al cliente.'
    },
    { 
      icon: <Lock />, 
      title: 'Cumplimiento Normativo', 
      description: 'Asegura el cumplimiento de las regulaciones locales de edad y capacidad, evitando multas y problemas legales.'
    }
  ]

  return (
    <section id="beneficios" className="benefits">
      <div className="container">
        <motion.h2 
          variants={fadeInUp} 
          initial="hidden"
          animate="visible"
          className="section-title"
        >
        ¿Por qué Escana?
        </motion.h2>
        <motion.p 
          variants={fadeInUp} 
          initial="hidden"
          animate="visible"
          className="section-subtitle"
        >
          Descubre cómo Escana transforma la gestión de tu local nocturno, mejorando la seguridad y la experiencia del cliente.
        </motion.p>
        <motion.div 
          variants={staggerChildren}
          initial="hidden"
          animate="visible" 
          className="benefits-grid"
        >
          {benefits.map((benefit, index) => (
            <motion.div key={index} variants={fadeInUp} className="benefit-card">
              <div className="benefit-icon-wrapper">
                {benefit.icon}
              </div>
              <h3 className="benefit-title">{benefit.title}</h3>
              <p className="benefit-description">{benefit.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  )
}