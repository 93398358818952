import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context';
import Scana from './component/scana';
import EscanaLandingPage from './component/componentes/EscanaLandingPage';
import ProtectedRoute from './ProtectedRoute'; // Importa el nuevo componente

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <EscanaLandingPage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
