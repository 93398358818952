import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const CONFIG = {
  SESSION_TIMEOUT: 900, // 15 minutos
  WARNING_TIME: 60     // Mostrar advertencia en el último minuto
};

// Contraseñas del sistema
const PASSWORDS = {
  MASTER: 'zealot15',
  TEMP1: '34fdvd',
  TEMP2: '5kjfds42'
};



const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(CONFIG.SESSION_TIMEOUT);
  const [inputPassword, setInputPassword] = useState('');
  const [error, setError] = useState('');
  
  // Estado para las 2 contraseñas temporales
  const [tempPasswords, setTempPasswords] = useState([]);
  const [usedPasswords, setUsedPasswords] = useState(new Set());

  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    infoBar: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: '#1a365d',
      color: 'white',
      padding: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 9999,
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    infoContainer: {
      display: 'flex',
      gap: '32px',
      alignItems: 'center'
    },
    passwordDisplay: {
      display: 'flex',
      gap: '24px'
    },
    passwordItem: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'rgba(255,255,255,0.1)',
      padding: '12px',
      borderRadius: '4px',
      minWidth: '200px'
    },
    passwordLabel: {
      fontSize: '14px',
      color: '#a0aec0',
      marginBottom: '4px'
    },
    passwordValue: {
      fontFamily: 'monospace',
      fontSize: '16px'
    },
    usedBadge: {
      backgroundColor: '#ff4444',
      color: 'white',
      padding: '2px 6px',
      borderRadius: '4px',
      fontSize: '12px',
      marginLeft: '8px'
    },
    timer: {
      fontFamily: 'monospace',
      backgroundColor: 'rgba(255,255,255,0.1)',
      padding: '8px 12px',
      borderRadius: '4px',
      minWidth: '80px',
      textAlign: 'center'
    },
    warningTime: {
      backgroundColor: '#ff4444'
    },
    button: {
      backgroundColor: 'rgba(255,255,255,0.1)',
      border: 'none',
      color: 'white',
      padding: '8px 16px',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s'
    },
    loginContainer: {
      minHeight: '100vh',
      backgroundColor: '#f8fafc',
      paddingTop: '96px'
    },
    loginBox: {
      width: '400px',
      margin: '0 auto',
      padding: '32px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '24px',
      color: '#1a365d'
    },
    errorMessage: {
      backgroundColor: '#fff5f5',
      border: '1px solid #fc8181',
      color: '#c53030',
      padding: '12px',
      borderRadius: '4px',
      marginBottom: '16px'
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    },
    input: {
      width: '100%',
      padding: '8px 12px',
      border: '1px solid #e2e8f0',
      borderRadius: '4px',
      fontSize: '16px',
      outline: 'none'
    },
    contentContainer: {
      marginTop: '60px',
      padding: '20px'
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(prev => {
        if (prev <= 1) {
          // Resetear el tiempo y las contraseñas usadas
          if (!isMaster) {
            setIsAuthenticated(false);
          }
          setUsedPasswords(new Set());
          return CONFIG.SESSION_TIMEOUT;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isMaster]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (inputPassword === PASSWORDS.MASTER) {
      setIsAuthenticated(true);
      setIsMaster(true);
      setError('');
    } else if (
      inputPassword === PASSWORDS.TEMP1 || 
      inputPassword === PASSWORDS.TEMP2
    ) {
      if (usedPasswords.has(inputPassword)) {
        setError('Esta contraseña ya ha sido utilizada');
      } else {
        setIsAuthenticated(true);
        setIsMaster(false);
        setError('');
        setUsedPasswords(prev => new Set([...prev, inputPassword]));
      }
    } else {
      setError('Contraseña incorrecta');
    }
    setInputPassword('');
  };

  const InfoBar = () => (
    <div style={styles.infoBar}>
      <div style={styles.infoContainer}>
        {isMaster && (
          <div style={styles.passwordDisplay}>
            <div style={styles.passwordItem}>
              <span style={styles.passwordLabel}>Contraseña 1</span>
              <span style={styles.passwordValue}>
                {PASSWORDS.TEMP1}
                {usedPasswords.has(PASSWORDS.TEMP1) && (
                  <span style={styles.usedBadge}>USADA</span>
                )}
              </span>
            </div>
            <div style={styles.passwordItem}>
              <span style={styles.passwordLabel}>Contraseña 2</span>
              <span style={styles.passwordValue}>
                {PASSWORDS.TEMP2}
                {usedPasswords.has(PASSWORDS.TEMP2) && (
                  <span style={styles.usedBadge}>USADA</span>
                )}
              </span>
            </div>
          </div>
        )}
        <div style={{
          ...styles.timer,
          ...(timeRemaining <= CONFIG.WARNING_TIME ? styles.warningTime : {})
        }}>
          {formatTime(timeRemaining)}
        </div>
      </div>
      {isAuthenticated && (
        <button
          onClick={() => {
            setIsAuthenticated(false);
            setIsMaster(false);
          }}
          style={styles.button}
          onMouseOver={e => e.target.style.backgroundColor = 'rgba(255,255,255,0.2)'}
          onMouseOut={e => e.target.style.backgroundColor = 'rgba(255,255,255,0.1)'}
        >
          Cerrar Sesión
        </button>
      )}
    </div>
  );
  
  

  if (!isAuthenticated) {
    return (
      <div style={styles.loginContainer}>
        <InfoBar />
        <div style={styles.loginBox}>
          <h2 style={styles.title}>Acceso Protegido</h2>
          
          {error && (
            <div style={styles.errorMessage}>
              <p>{error}</p>
            </div>
          )}
          
          <form onSubmit={handleLogin} style={styles.form}>
            <input
              type="password"
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
              style={styles.input}
              placeholder="Ingrese la contraseña"
              required
            />
            <button
              type="submit"
              style={styles.button}
              onMouseOver={e => e.target.style.backgroundColor = '#2c5282'}
              onMouseOut={e => e.target.style.backgroundColor = '#3182ce'}
            >
              Ingresar
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.wrapper}>
      <InfoBar />
      <div style={styles.contentContainer}>
        {children}
      </div>
    </div>
  );
};

export default ProtectedRoute;