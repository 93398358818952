import React from 'react'
import { motion } from 'framer-motion'
import { CheckCircle, ArrowRight } from 'lucide-react'
import Escaneo from './mokup.png'

export default function Hero() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  }

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
  }

  const benefits = [
    'Verificación de identidad en 2 clicks',
    'Listas inteligentes de acceso',
    'Análisis en tiempo real',
    'Mejora la seguridad del cliente',
    'Optimiza la eficiencia operativa'
  ]

  return (
    <section className="hero">
      <div className="container hero-container">
        <motion.div
          className="hero-content"
          initial="hidden"
          animate="visible"
          variants={staggerChildren}
        >
          <motion.h1 variants={fadeInUp} className="hero-title">
            Verifica Identidades.{' '}
            <span className="hero-title-highlight">Protege tu Local.</span>
          </motion.h1>
          <motion.p variants={fadeInUp} className="hero-subtitle">
            Escana revoluciona la seguridad en locales nocturnos con verificación instantánea y análisis en tiempo real.
          </motion.p>
          <motion.ul variants={staggerChildren} className="hero-benefits">
            {benefits.map((benefit, index) => (
              <motion.li key={index} variants={fadeInUp} className="hero-benefit">
                <CheckCircle className="hero-benefit-icon" />
                <span>{benefit}</span>
              </motion.li>
            ))}
          </motion.ul>
         
        </motion.div>
        <motion.div
          className="hero-image-container"
          variants={fadeInUp}
          initial="hidden"
          animate="visible"
        >
          <img
            src={Escaneo}
            alt="Escana App Demo"
            className="hero-image"
          />
        </motion.div>
      </div>
    </section>
  )
}